export default theme => ({
  	root: {
    	backgroundColor: theme.palette.primary.main,
    	color: theme.palette.primary.contrastText,
    	'& > .MuiGrid-item': {
      		padding: theme.spacing(4),
		    display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center'
		}
  	}
});